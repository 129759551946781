import React from 'react';
import Link from 'next/link';

import Head from 'components/partials/Head';

const NotFoundPage = () => (
  <div>
    <Head />
    <div className="container-fluid mt-2 mt-sm-5">
      <div className="row mb-4">
        <div className="col offset-sm-1">
          <a href="/">
            <div className="logo">
              <img src="/image/logo-siyah.svg" alt="" />
            </div>
          </a>
        </div>
      </div>
      <div className="row flex-column align-items-center">
        <div className="col-12 col-sm-10 col-xl-6">
          <img className="w-100" src="/image/404.svg" alt="" />
        </div>
        <div className="col-15 text-center mt-30 text-gray10">
          <span className="fs-20 fw-400 text-gray10">Aradığınız sayfa bulunamadı.</span>
        </div>
      </div>
      <div className="row justify-content-center mt-30">
        <div className="col col-sm-5 col-lg-5 col-xl-3">
          <Link href="/">
            <a className="im-button im-button--primary im-button--fluid im-button--size-40 im-button--center">Anasayfaya Dön</a>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
